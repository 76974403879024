var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Modifier livreur"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez modifier les coordonnées de livreur ")])]),(_vm.delivery_man)?_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom"},model:{value:(_vm.delivery_man.username),callback:function ($$v) {_vm.$set(_vm.delivery_man, "username", $$v)},expression:"delivery_man.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2129006438)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"Téléphone"}},[_c('validation-provider',{attrs:{"name":"Téléphone","rules":"required|integer|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Téléphone "},model:{value:(_vm.delivery_man.phone),callback:function ($$v) {_vm.$set(_vm.delivery_man, "phone", $$v)},expression:"delivery_man.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3070825060)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.delivery_man.email),callback:function ($$v) {_vm.$set(_vm.delivery_man, "email", $$v)},expression:"delivery_man.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2463867836)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse","label-for":"adress"}},[_c('validation-provider',{attrs:{"name":"adress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Adresse"},model:{value:(_vm.delivery_man.address),callback:function ($$v) {_vm.$set(_vm.delivery_man, "address", $$v)},expression:"delivery_man.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3651977469)})],1)],1),(_vm.user.role ==='admin')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Dépot","label-for":"depot"}},[_c('validation-provider',{attrs:{"name":"depot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Dépots","label":"name","options":_vm.repositories},model:{value:(_vm.delivery_man.repository),callback:function ($$v) {_vm.$set(_vm.delivery_man, "repository", $$v)},expression:"delivery_man.repository"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1743955842)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Voiture"}},[_c('validation-provider',{attrs:{"name":"voiture"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Voiture","label":"serial_number","options":_vm.cars},model:{value:(_vm.delivery_man.car),callback:function ($$v) {_vm.$set(_vm.delivery_man, "car", $$v)},expression:"delivery_man.car"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,92544255)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.editDeliveryMan.apply(null, arguments)}}},[_vm._v(" modifier ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }