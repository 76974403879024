<template>
  <b-card-code title="Modifier livreur">
    <b-card-text>
      <span>Veuillez modifier les coordonnées de livreur </span>
    </b-card-text>

    <!-- form -->
    <validation-observer
      v-if="delivery_man"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  v-model="delivery_man.username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Téléphone"
              label-for="Téléphone"
            >
              <validation-provider
                #default="{ errors }"
                name="Téléphone"
                rules="required|integer|length:8"
              >
                <b-form-input
                  v-model="delivery_man.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  v-model="delivery_man.email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adresse"
              label-for="adress"
            >
              <validation-provider
                #default="{ errors }"
                name="adress"
              >
                <b-form-input
                  v-model="delivery_man.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Adresse"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="user.role ==='admin'"
            md="6"
          >
            <b-form-group
              label="Dépot"
              label-for="depot"
            >
              <validation-provider
                #default="{ errors }"
                name="depot"
              >
                <v-select
                  v-model="delivery_man.repository"
                  :clearable="false"
                  placeholder="Dépots"
                  label="name"
                  :options="repositories"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Voiture"
            >
              <validation-provider
                #default="{ errors }"
                name="voiture"
              >
                <v-select
                  v-model="delivery_man.car"
                  :clearable="false"
                  placeholder="Voiture"
                  label="serial_number"
                  :options="cars"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editDeliveryMan"
            >
              modifier
            </b-button>

          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'
import storeAuth from '@/store/store'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      respository: '',
      repositories: [],
      cars: [],
      delivery_man: {},
      required,
      isLoading: false,
      user: {},
    }
  },
  created() {
    this.getDeliveryMan()
    this.getCars()
    this.getRepositories()
    this.user = storeAuth.state.user
  },
  methods: {
    async getRepositories() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/repositories/')
        this.repositories = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'Network error: ')
      }
    },
    async getCars() {
      try {
        this.isLoading = true
        const { data } = await axios.get('/api/cars/')
        this.cars = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'Network error: ')
      }
    },
    async getDeliveryMan() {
      const { id } = this.$route.params
      try {
        this.isLoading = true
        const { data } = await axios.get(`/api/auth/users/detail/${id}`)
        this.delivery_man = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'Network error: ')
      }
    },
    async editDeliveryMan() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const payload = {
              username: this.delivery_man.username,
              phone: this.delivery_man.phone,
              email: this.delivery_man.email,
              address: this.delivery_man.address,
              car: this.delivery_man.car.id,
            }
            if (this.user.role === 'admin') {
              payload.repository = this.delivery_man.repository.id
            }
            await axios.put(`/api/auth/users/update/${this.delivery_man.id}/`, payload)
            this.$router.push('/delivery-mans/')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Modification effectuée avec succès')
            }, 1000)
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000)
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
